<template>
  <div class="">
    <el-dialog title="设置代金券"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="代金券批次号"
                      prop="id">
          <el-select v-model="form.id"
                     :disabled="(setIndex || setIndex === 0)?true:false"
                     filterable
                     @change="couponChange"
                     class="width100"
                     remote
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.coupon_stock_id"
                       :value="item.id">
              {{item.title}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="库存数量"
                      prop="total_inventory">
          <el-input type="text"
                    placeholder="请输入库存数量"
                    v-model.number="form.total_inventory"></el-input>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { fetchVouchers } from '@/api/market'
export default {
  props: ['aid'],
  data () {
    return {
      dialogVisible: false,
      options: [],
      form: {
        id: '',
        total_inventory: 0,
        coupon_name: '',
        receive_count: 0,
        stock_id: ''
      },
      setIndex: null,
      rules: {
        id: [
          { required: true, message: '请输入代金券批次号', trigger: 'change' }
        ],
        total_inventory: [
          { required: true, message: '请输入总库存', trigger: 'blur' }
        ]
      },
      prizeTypes: [
        {
          value: 'COUPON',
          label: '代金券'
        }
      ]
    }
  },
  components: {
  },
  methods: {
    couponChange (e) {
      const res = this.options.find((item) => {
        return item.id === e
      })
      this.form = {
        id: res.id,
        total_inventory: res.total_inventory,
        coupon_name: res.title,
        receive_count: 0,
        stock_id: res.coupon_stock_id
      }
    },
    async remoteMethod (query) {
      if (query !== '') {
        const res = await fetchVouchers({
          coupon_stock_id: query
        })
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    showDialog (scoped) {
      this.dialogVisible = true
      if (scoped && scoped.row) {
        this.setIndex = scoped.$index
        const formData = JSON.parse(JSON.stringify(scoped.row))
        this.form = formData
        this.options = [{
          id: formData.id,
          coupon_stock_id: formData.stock_id,
          title: formData.coupon_name,
          total_inventory: formData.total_inventory
        }]
      } else {
        this.form = {
          id: '',
          total_inventory: 0,
          coupon_name: '',
          receive_count: 0,
          stock_id: ''
        }
        this.setIndex = null
        this.options = []
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.setIndex || this.setIndex === 0) {
            this.$emit('success', this.form, this.setIndex)
          } else {
            this.$emit('success', this.form, null)
          }

          this.dialogVisible = false
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
