<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动Logo图">
        <ImgUpload :fileList.sync="logoList"
                   pixel="建议使用120*120像素"
                   v-model="form.logo_img_id" />
      </el-form-item>
      <el-form-item label="背景图">
        <ImgUpload :fileList.sync="imgList2"
                   multiple
                   pixel="单图建议640*862像素，若上传多图则拼接为长图"
                   v-model="form.bg_img" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="请输入活动规则"
                  v-model="form.rule">
        </el-input>
      </el-form-item>
      <el-form-item label="领取类型"
                    required>
        <el-radio-group v-model="form.receive_type">
          <el-radio :label="1">随机领取</el-radio>
          <el-radio :label="2">批量领取</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否设置白名单">
        <el-switch v-model="form.is_whitelist"
                   :active-value='1'
                   :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="是否需要填写表单">
        <el-switch v-model="form.is_register"></el-switch>
      </el-form-item>
      <el-form-item label="是否显示于网点主页">
        <el-switch v-model="form.is_list"></el-switch>
      </el-form-item>
      <el-form-item label="日期规则"
                    required>
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item label="活动可参与总次数"
                    prop="total_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.total_max_num"></el-input>
        <p class="input-tip">请填数字，不可为空</p>
      </el-form-item>
      <el-form-item label="活动期间每天可参与的总次数"
                    prop="day_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.day_max_num"></el-input>
        <p class="input-tip">请填数字，默认为空，即不限制次数</p>
      </el-form-item>
      <el-form-item label="每个用户可参与次数"
                    prop="user_total_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.user_total_max_num"></el-input>
        <p class="input-tip">请填数字，默认为1</p>
      </el-form-item>
      <el-form-item label="用户每天可参与次数"
                    prop="user_day_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder=""
                  v-model.number="form.user_day_max_num"></el-input>
        <p class="input-tip">请填数字，默认为1</p>
      </el-form-item>
      <el-form-item label="关联优惠券"
                    prop="coupons">
        <el-button type="text"
                   @click="$refs.setCoupon.showDialog()">添加代金券</el-button>
        <el-row>
          <el-table :data="form.coupons"
                    stripe>
            <el-table-column label="代金券名称"
                             prop="coupon_name">
            </el-table-column>
            <el-table-column label="批次号"
                             prop="stock_id">
            </el-table-column>
            <el-table-column prop="receive_count"
                             label="已领数量">
            </el-table-column>
            <el-table-column prop="total_inventory"
                             label="总数量">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           @click="$refs.setCoupon.showDialog(scope)">编辑</el-button>
                <el-button type="text"
                           @click="delCoupon(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
    <SetCoupon ref="setCoupon"
               @success="getCoupon" />
  </div>
</template>

<script>
import { fetchAllotCards, setActivity, fetchVouchers, getActivity } from '@/api/market'
import SetCoupon from './components/SetCoupon'
import ImgUpload from '@/components/ImgUpload'
import WxDate from '@/components/WxDate'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetPublic',
  data () {
    const checkDayMaxNum = (rule, value, callback) => {
      if (this.form.total_max_num === '') {
        this.$refs.form.validateField('total_max_num')
      } else {
        if (value > this.form.total_max_num) {
          callback(new Error('活动期间每天可参与的总次数不能大于活动参与总次数'))
        } else {
          callback()
        }
      }
    }
    const checkUserTotalMaxNum = (rule, value, callback) => {
      if (this.form.total_max_num === '') {
        this.$refs.form.validateField('total_max_num')
      } else {
        if (value > this.form.total_max_num) {
          callback(new Error('每个用户可参与总次数不能大于活动参与总次数'))
        } else {
          callback()
        }
      }
    }
    const checkUserDayMaxNum = (rule, value, callback) => {
      if (value > this.form.day_max_num) {
        callback(new Error('用户每天可参与次数不能大于活动期间每天可参与的总次数'))
      } else if (value > this.form.user_total_max_num) {
        callback(new Error('用户每天可参与次数不能大于用户可参与总次数'))
      } else {
        callback()
      }
    }
    return {
      dateRule: {},
      value: '',
      couponList: [],
      imgList: [],
      imgList2: [],
      logoList: [],
      dateTime: [],
      selectLoading: false,
      address: {},
      areaAddress: {},
      coupons: [],
      vouchers: [],
      refuels: [],
      options: [],
      form: {
        type: 'PUBLIC',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        title: '',
        bg_img: [],
        logo_img_id: '',
        rule: '',
        receive_type: 1,
        is_whitelist: false,
        is_register: false,
        is_list: false,
        is_index: false,
        city: '',
        province: '',
        district: '',
        img_id: '',
        started_at: '',
        ended_at: '',
        total_max_num: 999999,
        day_max_num: '',
        user_total_max_num: 1,
        user_day_max_num: 1,
        coupons: [],
        banner_img_id: '',
        time_rule: 'ANY',
        time_rule_day: [[]],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7]
      },
      rules: {
        list_province: [
          { required: true, message: '至少选择到省', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        bg_img: [
          { type: 'array', required: true, message: '请上传背景图片', trigger: 'change' }
        ],
        img_id: [
          { required: true, message: '请上传首页活动图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ],

        total_max_num: [
          { required: true, message: '请输入活动总参与次数', trigger: 'blur' }
        ],
        day_max_num: [
          { required: true, message: '请输入活动期间每天可参与总次数', trigger: 'blur' },
          { validator: checkDayMaxNum, trigger: 'blur' }
        ],
        user_total_max_num: [
          { required: true, message: '请输入每个用户可参与次数', trigger: 'blur' },
          { validator: checkUserTotalMaxNum, trigger: 'blur' }
        ],
        user_day_max_num: [
          { required: true, message: '请输入用户每天可参与次数', trigger: 'blur' },
          { validator: checkUserDayMaxNum, trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        coupons: [
          { required: true, message: '请添加代金券', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area,
    ImgUpload,
    WxDate,
    SetCoupon
  },
  methods: {
    delCoupon (index) {
      this.form.coupons.splice(index, 1)
    },
    getCoupon (e, index) {
      if (index || index === 0) {
        this.$set(this.form.coupons, index, e)
      } else {
        this.form.coupons.push(e)
      }
    },
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    close (index) {
      this.form.coupon_ids.splice(index, 1)
      this.form.coupon_stock_ids.splice(index, 1)
      this.couponList.splice(index, 1)
    },
    onClick (item) {
      const index = this.form.coupon_ids.findIndex((id) => {
        return parseInt(id) === parseInt(item.id)
      })
      if (index !== -1) {
        this.$message.error('请勿添加相同关联券')
      } else {
        this.form.coupon_ids.push(item.id)
        this.form.coupon_stock_ids.push(item.coupon_stock_id)
        this.couponList.push(item)
      }
    },
    addressChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchVouchers({
          page: 1,
          size: 5,
          coupon_stock_id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    async fetchAllotCards (val) {
      const res = await fetchAllotCards({
        type: val
      })
      if (res.meta.code === 0) {
        if (val === 'VOUCHER') {
          this.coupons = res.data.allots
        } else if (val === 'REFUEL') {
          this.refuels = res.data.allots
        }
      }
    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (wxDateStatus) {
            const couponConfigs = []
            this.form.coupons.map((item) => {
              couponConfigs.push({
                id: item.id,
                total_inventory: item.total_inventory
              })
            })
            this.form.coupon_config = couponConfigs
            const res = await setActivity(this.form)
            if (res.meta.code === 0) {
              this.$notify({
                title: '成功',
                message: `${this.form.id ? '修改' : '创建'}成功`,
                type: 'success'
              })
              this.$router.push('public')
            } else {
              this.$message.error(res.meta.msg)
            }
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity

        activity.activity_id = id
        const imgList2 = [...activity.bg_img]
        activity.bg_img = getImgId(activity.bg_img)

        activity.img_id = getImgId(activity.img)

        activity.logo_img_id = getImgId(activity.logo_img_data)
        activity.logo_img_data && this.$set(this.logoList, 0, activity.logo_img_data)

        this.address = {
          province: activity.province,
          city: activity.city,
          district: activity.district
        }
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        this.$nextTick(() => {
          this.imgList2 = imgList2
          activity.img && this.$set(this.imgList, 0, activity.img)
        })
        this.form = activity
      }
    }
  },
  mounted () {
    this.fetchAllotCards('VOUCHER')
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
